
.form-control {
  border-radius: 9999px;
  padding: 0.875rem 1.75rem;
  border: 1px solid $gray;
  color: $gray-dark;

  &:focus {
    color: $gray-dark;
  }

  &::placeholder {
    color: $gray;
  }
}

.form-group {
  label {
    margin-bottom: 5px;
  }
}

//// Select & Input

select.form-control, input.form-control {
  height: 58px !important;
}

.scanner-selectWrapper {
  position: relative;
  width: 100%;

  @media screen and (min-width: 567px) {
    width: 300px;
  }
}

.scanner-selectArrowHighlighted {
  width: 63px;
  height: 56px;
  position: absolute;
  display: inline-block;
  right: -4px;
  top: 2px;
  background: transparent;
  border-radius: 50px;
  pointer-events: none;

  img {
    margin-top: 15px;
    margin-left: 22px;
  }
}

//// Select from React

.scanner-select div[class$="-control"]{
    border-radius: 9999px !important;
    height: 58px;
    padding-left: 1rem;
    cursor: pointer !important;
}

//// Checkbox design

[type="checkbox"] {
  position: relative;
  left: 15px;
  top: 30px;
  z-index: 0;
  -webkit-appearance: none;
}

[type="checkbox"] + label {
  position: relative;
  display: table-cell;
  cursor: pointer;
  padding-left: 65px;
}

[type="checkbox"] + label:before,
.scanner-trTool-reviewList input[type="checkbox"]:before,
.scanner-trTool-reviewList input[name="select-all-rows"]:before {
  width: 50px;
  height: 26px;
  border-radius: 30px;
  border: 1px solid $gray;
  content: "";
  background-color: $gray-light;
  margin-right: 15px;
  @extend .scanner-transition-fade;
  z-index: 1;
  position: absolute;
  left: 0px;
}

[type="checkbox"] + label:after,
.scanner-trTool-reviewList input[type="checkbox"]:after,
.scanner-trTool-reviewList input[name="select-all-rows"]:after {
  width: 24px;
  height: 24px;
  border-radius: 30px;
  background-color: white;
  content: "";
  transition: margin 0.1s linear;
  border: 1px solid $gray;
  position: absolute;
  left: 1px;
  top: 1px;
  z-index: 2;
}

[type="checkbox"]:checked + label:before,
.scanner-trTool-reviewList input[type="checkbox"]:checked:before,
.scanner-trTool-reviewList input[name="select-all-rows"]:checked:before {
  background-color: $primary;
  border: 1px solid white;
}

[type="checkbox"]:checked + label:after,
.scanner-trTool-reviewList input[type="checkbox"]:checked:after,
.scanner-trTool-reviewList input[name="select-all-rows"]:checked:after {
  margin: 0 0 0 24px;
}

.scanner-trTool-reviewList input[type="checkbox"],
.scanner-trTool-reviewList input[name="select-all-rows"] {
  left: 0 !important;
  top: -13px;
}