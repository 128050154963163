
.card {
  border: 1px solid $gray;
  border-radius: 0;

  .card-body {
    padding: 1rem;

    @media screen and (min-width: 576px) {
      padding: 2rem;
    }
  }

  .card-body-muted {
    .card-overlay-muted {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255,255,255,.8);
    }
  }
}
