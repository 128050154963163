
.height-100vh {
  height: 100vh;
}

.white {
  color: #fff;
}

.red {
  color: $primary;
}

.border-top-big {
  border-top: 10px solid $primary;
}

.min-height-100vh {
  min-height: 100vh;
}

.min-width-1030px {
  min-width: 1030px;
}

.regular {
  font-weight: 600 !important;
}

.normal {
  font-weight: 400 !important;
}

.pos-abs {
  position: absolute;
}

.pos-rel {
  position: relative;
}

.right-25 {
  right: 25px;
}

.top-20 {
  top: 20px;
}

.cursor-pointer {cursor: pointer;}
.cursor-crosshair {cursor: crosshair;}
.cursor-eresize {cursor: e-resize;}
.cursor-move {cursor: move;}