
.btn {
  border-radius: 9999px;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 15px 20px;
}



.btn-sm {
  font-size: 0.9rem;
  padding: 10px 15px;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  color: #fff;

  &:hover {
    opacity: .75;
    background-color: $primary;
    border-color: $primary;
    color: #fff;
    @extend .scanner-transition-fade;
  }
}


.MuiButton-containedError {
  background-color: $secondary!important;
}

.btn-outline-primary {
  border-color: $primary;
  color: $primary;

  &:hover {
    background-color: transparent;
    opacity: .5;
    color: $primary;
    border-color: $primary;
    @extend .scanner-transition-fade;
  }
}

.btn-icon {
  border: 0;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;

  &:hover {
    opacity: .75;
  }
}

.btn-border-right {
  border-right: 2px solid $gray;
  padding-right: 1rem;

  &:hover {
    opacity: .75;
    border-right: 2px solid $gray;
  }
}