// Functions first
@import "../../../node_modules/bootstrap/scss/functions";

// Variable overrides second
$grey:                        #343d46 !default;
$primary:                     $grey !default;
$gray:                        #2C4251 !default;
$gray-light:                  #f8f8f8 !default;
$gray-dark:                   #464646 !default;
$red:                         #CC293D;
$secondary:                   $red !default;
$enable-shadows:              true;
$prefix:                      "scanner-";
$font-family-sans-serif:      "Noto Sans", Calibri, Arial, sans-serif !default;

// Import all Bootstrap
@import "~bootstrap/scss/bootstrap";

// Import overrides from Bootstrap
@import "_navbar.scss";
@import "_utilities.scss";
@import "_modal.scss";
@import "_buttons.scss";
@import "_type.scss";
@import "_tables.scss";
@import "_card.scss";
@import "_forms.scss";

@import "my";

// Required Bootstrap imports
//@import "../../../node_modules/bootstrap/scss/variables";
//@import "../../../node_modules/bootstrap/scss/maps";
//@import "../../../node_modules/bootstrap/scss/mixins";
//@import "../../../node_modules/bootstrap/scss/root";

// Optional components
//@import "../../../node_modules/bootstrap/scss/utilities";
//@import "../../../node_modules/bootstrap/scss/reboot";
//@import "../../../node_modules/bootstrap/scss/containers";
//@import "../../../node_modules/bootstrap/scss/grid";
//@import "../../../node_modules/bootstrap/scss/helpers";
//@import "../../../node_modules/bootstrap/scss/utilities/api";
//@import "../../../node_modules/bootstrap/scss/tables";
//@import "../../../node_modules/bootstrap/scss/forms";