
.table {
  margin-bottom: 0;
  color: $gray-dark;

  > :not(caption) > * > * {
    border-bottom-width: 0;
  }

  tr {
    border-bottom: 1px solid $gray-light;
    td {
      padding: 0.8rem 0.5rem;
    }
  }
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
  margin-bottom: 0;
}