
//// Modal Jump to top Bug Fix

body.modal-open {
  overflow: visible;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-right: 0 !important;
}

//// Standard Modal

#modal {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header {
  border-bottom: 1px solid $gray;
  background-color: $primary;
  color: #fff;

  .btn-close {
    background: transparent url(../../assets/img/icons/close-white.svg) center/1em auto no-repeat;
    opacity: 1;
    @extend .scanner-transition-fade;

    &:hover {
      opacity: .75;
    }
  }
}

#modal:empty {
  display: none;
}

#modal .modal {
  display: block;
}