
.navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: 68px;
  height: 100vh;
  background-color: $primary;
  padding: .5rem 1rem 0 0;
  transition: .5s;
  z-index: 4;

  @media screen and (min-width: 768px) {
    left: 0;
    padding: 1rem 1rem 0 0;
  }

  &.navbar-show {
    left: 0;

    @media screen and (min-width: 768px) {
      width: calc(68px + 156px);
    }
  }

  .nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  .nav-link {
    position: relative;
    margin-bottom: 1.5rem;
    transition: .3s;
    color: #fff;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem;

    &:hover {
      opacity: .5;
    }

    &.active {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 2px;
        height: 32px;
        background-color: #fff;
      }
    }
  }

  .navbar-brand {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem;
    margin-bottom: 2rem;
    padding-left: 8px;
  }
}