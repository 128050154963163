// Body

body {
    position: relative;
    padding: 0 1rem;
    transition: .5s;
    color: $gray-dark;

    @media screen and (min-width: 768px) {
        padding-left: calc(68px + 2rem);
    }
}

#scanner-cm-body {
    background-color: $gray-light;
    background-image: url("../../assets/img/spider-for-bg.png");
    background-repeat: no-repeat;
    background-position: 100px 350px;
    background-size:auto;

    @media screen and (min-width: 768px) {
        background-position: 200px 163px;
    }

    @media screen and (min-width: 992px) {
        background-position: 300px 163px;
    }

    @media screen and (min-width: 1200px) {
        background-position: 160px;
    }
}

.scanner-cm-body {
    padding-left: calc(68px + 1rem) !important;

    @media screen and (min-width: 768px) {
        padding-left: calc(68px + 188px) !important;
    }
}


// Header

.scanner-cm-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    transition: .5s;
    padding: 0 1rem;
    height: 45px;
    z-index: 3;

    @media screen and (min-width: 768px) {
        height: 64px;
        padding-left: calc(68px + 2rem);
    }

    .scanner-cm-header-toggle {
        float: left;
        cursor: pointer;
    }

    .scanner-cm-header-login {
        float: right;

        .scanner-cm-header-loginImg {
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            border-radius: 50%;
            overflow: hidden;
            float: left;

            @media screen and (min-width: 768px) {
                width: 40px;
                height: 40px;
            }

            img {
                width: 40px;
                float: left;

                @media screen and (min-width: 768px) {
                    width: 45px;
                }
            }
        }

        .scanner-cm-header-loginBtn {
            float: right;
            margin-left: 10px;
            margin-top: 7px;

            img {
                cursor: pointer;
                margin-bottom: 5px;
            }
        }
    }
}


// Main

.scanner-cm-main {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;

    @media screen and (min-width: 768px) {
        padding-bottom: 3rem !important;
    }

    // Dirty Fix for Login page
    .scanner-cm-main-rowLogin {
        margin-top: -6rem !important;
        margin-left: -1rem !important;
        margin-bottom: -48px !important;

        @media screen and (min-width: 768px) {
            margin-left: -6.25rem !important;
        }
    }
}


// Footer

.scanner-cm-footer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #fff;
    transition: .5s;
    border-top: 1px solid $gray;
    z-index: 3;
}


// Transitions

.scanner-transition-fade {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}


// Main Search Bar

#scanner-cm-searchBar,
#scanner-cm-tag-searchBar,
#scanner-cm-customers-searchBar,
#scanner-cm-license-searchBar {
    width: 100%;
    display: inline-block;

    @media screen and (min-width: 768px) {
        width: 550px;
    }
}

.scanner-cm-searchBar-btn {
    margin-bottom: 3px;
}


// Customer Info Navigation

.scanner-cm-customerInfoBar {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media screen and (min-width: 992px) {
        display: inline-block;
        float: right;
        margin-bottom: 0;
    }
}


// Search Bar in the DataTables (Overwrites)

main {
    header {
        padding: 0 !important;
        width: 100% !important;
        display: inline-block !important;
        float: right !important;
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;

        @media screen and (min-width: 768px) {
            width: 300px !important;
            margin-top: 0 !important;
        }

        .btn {
            position: absolute !important;
            top: 15px !important;
            right: 25px !important;
        }
    }
}


// RDT Tables (Job Info & Customer Info & TR Tool Tables)

.rdt_Table {
    .rdt_TableHead {
        border-bottom: none;
        color: $gray-dark;

        .rdt_TableHeadRow {
            font-weight: bold;
            font-size: 14px;
            padding-left: 5px;

            .rdt_TableCol {
                justify-content: center;

                div {
                    span {
                        opacity: 1;
                    }
                }
            }

            // Checkbox TableCol on TRTool Review
            .MHRqX {
                margin-right: 10px;
            }
        }
    }

    .rdt_TableBody {
        .rdt_TableRow, .cxcOiE:not(:last-of-type), .efVAPp:not(:last-of-type) {
            border-bottom-style: unset;
            border-bottom-width: unset;
            border-bottom-color: unset;
            margin-bottom: 1px;
            outline: none;
            cursor: default;
            background-color: #fff;
            color: $gray-dark;
            padding-left: 5px;

            &:hover {
                border-bottom-color: $gray;
                outline: none;
            }

            .rdt_TableCell {
                padding: 0;
                color: $gray-dark;
                justify-content: center;

                div {
                    display: flex;
                    vertical-align: middle;

                    &:first-child {
                        .btn {
                            font-size: 0.9rem;

                            svg {
                                display: none !important;
                            }
                        }
                    }
                }
            }

            // Checkbox Cell on TRTool Review
            .cpdasa {
                margin-right: 10px;
            }
        }
    }
}

.scanner-trTool-leftCenteredList {
    .rdt_Table {
        .rdt_TableHead {
            .rdt_TableHeadRow {
                .rdt_TableCol {
                    justify-content: left;
                    padding: 0;
                }

                div {
                    &:first-child  {
                        padding: 0;
                    }
                }
            }
        }

        .rdt_TableBody {
            .rdt_TableRow {
                .rdt_TableCell {
                    justify-content: left;

                    div {
                        display: block;
                    }
                }
            }
        }
    }
}


// Spinner Loader

.scanner-cm-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999999;

    .spinner {
        .spinner-border {
            border-top: 3px solid $primary;
            border-bottom: 3px solid $primary;
            border-left: 3px solid $primary;
        }
    }
}